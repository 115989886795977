import React from "react"
import { Box, Grid } from "@chakra-ui/core"

import Layout from "../components/layout"

export default ({ pageContext: { brand } }) => (
  <Layout>
    <h2>{brand.name}</h2>
    <Grid
      templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(5, 1fr)"]}
      gap={6}
    >
        {brand.inventory_item.map((product) => (
          <Box>
            <img
              src={product.images?.[0].fixed.src}
              width={product.images?.[0].fixed.width}
              height={product.images?.[0].fixed.height}
              alt={product.name} />
            <a href={`/en/products/${product.slug}`}>{product.name}</a>
          </Box>
        ))}
    </Grid >
  </Layout>
)